import React from 'react';
import appStoreBadge from './Apple_App_Store_Badge.svg';
import playStoreBadge from './Google_Play_Store_Badge.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <main className="App-content">
        <a href="https://apps.apple.com/us/app/shotwheelapp/id1185394883">
          <img src={appStoreBadge} className="App-logo" alt="logo" />
        </a>
        <a href="https://play.google.com/store/apps/details?id=info.tomesposito.shotwheel">
          <img src={playStoreBadge} className="App-logo" alt="logo" />
        </a>
      </main>
      <footer className="App-footer">
        <p><a href="https://app.termly.io/document/privacy-policy/a6ce4031-8551-49d2-8d82-29695aaaf2e7">Privacy Policy</a></p> 
      </footer>
    </div>
  );
}

export default App;
